import * as React from "react";
import { AuthState, NotificationMessage, NotificationType, UserState } from "../user/UserState";
import { Auth } from "@awsscm/awsscm-auth-manager/auth/AuthManager";
import { Box, Button, FormField, Input, Link, Modal, SpaceBetween } from "@amzn/awsui-components-react-v3";
import logo from '../AWS_Infrastructure.svg';
import { NotificationBox } from "../notification/NotificationBox";
import "./LoginPanel.scss";
import "../AWS_Infrastructure.scss";
import { Header } from "./Header";

export interface LoginPanelProps {
  userState: UserState,
  setUserState: (userState: UserState) => void
}

/**
 * React component to handle login operations and with entry points for user password reset and SSO users.
 * @param props component props.
 */
export const LoginPanel = (props: LoginPanelProps) => {
  const [username, setUsername] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [signInLoading, setSignInLoading] = React.useState<boolean>(false);

  const resetMessageStatus = () => {
    if (props.userState.message !== null) {
      props.userState.message = null;
      props.setUserState(props.userState);
    }
  }

  const submitButtonEnabled = () : boolean => {
    return username !== "" && password !== "";
  };

  // function to perform username and password sign in
  const submitSignIn = (username: string, password: string) => {
    setSignInLoading(true);
    Auth.landingPageSignIn(username, password).then(user => {
      setSignInLoading(false);
      props.setUserState({authenticated: false, authState: AuthState.MFA, user: user, message: null})
    }).catch(e => {
      setSignInLoading(false);
      const errorMessage: NotificationMessage = {
        message: "Incorrect password or email address",
        type: NotificationType.ERROR
      };
      props.setUserState({authenticated: false, authState: AuthState.SIGN_IN, user: null, message: errorMessage})
    })
  };

  // function to perform federated sign in
  const federatedSignIn = () => {
    let redirect_url;
    let customState = "/";
    if ((redirect_url = new URL(window.location.href).searchParams.get('redirect'))) {
      customState = redirect_url;
    }

    Auth.federatedSignIn("Amazon", customState).catch(e => {
      const errorMessage: NotificationMessage = {
        message: "User is unauthorized to perform federated sign in",
        type: NotificationType.ERROR
      };
      props.setUserState({authenticated: false, authState: AuthState.SIGN_IN, user: null, message: errorMessage})
    })
  };

  return (
    <div className={"awsscm-argo-login-panel"}>
      <Modal disableContentPaddings={false} size={"small"}
             visible={props.userState.authState == AuthState.SIGN_IN}
             // closeAriaLabel="Close modal"
            // headers
             header={<Header/>}
      >

        <SpaceBetween size={"m"} direction={"vertical"}>
          <Box>
            <Button variant="primary" id={"federated SignIn"} onClick={() => federatedSignIn()}>
              Sign in with corporate Amazon ID
            </Button>
          </Box>

          <div className={"awsscm-argo-landing-app-login-panel-split-line"}> <i>Or</i> </div>
          <div className={"awsscm-argo-landing-app-username-pwd-form-fields"}>
            <SpaceBetween size='m'>
              <span className="awsscm-argo-landing-app-vendor-signin">Vendor sign in</span>
              <FormField test-dataid={"username"} label="Email address">
                <Input
                  className={"awsscm-argo-landing-app-login-username-input"}
                  onChange={({ detail }) => {
                    setUsername(detail.value.replaceAll(" ", ""));
                    resetMessageStatus();
                  }} value={username} placeholder="Email address" disableBrowserAutocorrect={true} />
              </FormField>

              <FormField className={"awsscm-argo-landing-app-pwd-form-field"} label="Password">
                <Input
                  className={"awsscm-argo-landing-app-login-pwd-input"}
                  test-dataid={"password-input"}
                  onChange={({ detail }) => {
                    setPassword(detail.value);
                    resetMessageStatus();
                  }}
                  value={password} onKeyDown={(event) => {
                    if (event.detail.keyCode === 13) {
                      submitSignIn(username, password);
                    }
                  }}
                  placeholder="Password" type={"password"} />
              </FormField>
            </SpaceBetween>
          </div>

          <NotificationBox userState={props.userState} setUserState={props.setUserState}/>

          <div className={"awsscm-landing-app-cta_wrapper"}>
            <Button className={"awsscm-landing-app-sign_in_button"}
                    onClick={() => submitSignIn(username, password)}
                    loading={signInLoading}
                    disabled={!submitButtonEnabled()}
                    variant={"primary"}
                    id={"signIn"}
            >
              Sign in
            </Button>
          
            <Link onFollow={() => {
              props.setUserState({
                authenticated: false, authState: AuthState.PASSWORD_RESET,
                user: null, message: null
              })
            }}>
              Forgot password?
            </Link>
          </div>

          <Box margin={{ top: 'm' }}>
            <span>Having trouble signing in?</span>
            <SpaceBetween direction="horizontal" size="xs">
              <Link href="https://w.amazon.com/bin/view/AWS_SCM/AuthTeam/LoginTroubleshooting/" variant="primary"  target="_blank">
                Corporate users
              </Link>
              <Link href="mailto:infra-portal-login@amazon.com" variant="primary">
                Vendor users
              </Link>
            </SpaceBetween>
          </Box>

        </SpaceBetween>
      </Modal>
    </div>
  );
}